import React, {useEffect, useRef} from "react"
import AutoplayVideo from "../Video/AutoplayVideo";

const Headshot = ({
  mp4,
  webm,
  name,
  title,
  pup_title,
  poster,
  pup_poster
}) => {
  const refVideo = useRef(null);

  useEffect(() => {
    if (!refVideo.current) {
        return;
    }

    refVideo.current.defaultMuted = true;
    refVideo.current.muted = true;
}, [refVideo]);

  return (
    <div className={`block text-center headshot-container`}>
      <div className={`block relative overflow-hidden mb-4 image-container`}>
        <AutoplayVideo mp4={mp4} webm={webm} className={`w-full h-auto`} poster={poster} />
        <img className={`w-auto h-auto absolute bottom-full headshot-pup`} src={pup_poster} />
      </div>
      <div className={`block relative overflow-hidden name-container`}>
        <h3 className={`text-xs md:text-base font-bold opacity-0`}>{name}</h3>
        <span className={`text-xs md:text-base font-bold absolute bottom-0 original-name w-full text-center block`}>{name}</span>
        <span className={`text-xs md:text-base font-bold absolute bottom-full pup-name w-full text-center block`}>{name}</span>
      </div>
      <div className={`block relative overflow-hidden breed-container`}>
        <h4 className={`text-xs md:text-base opacity-0`}>{title}</h4>
        <span className={`text-xs md:text-base absolute bottom-0 original-title w-full text-center block`}>{title}</span>
        <span className={`text-xs md:text-base absolute bottom-full pup-title w-full text-center block`}>{pup_title}</span>
      </div>
    </div>
    
  )
}

export default Headshot
