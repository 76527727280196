import * as React from "react"
import { Helmet } from "react-helmet"
import PrimaryButton from "../../components/Buttons/PrimaryButton"
import Headshot from "../../components/Headshot/Headshot"
import LinkFadeDown from "../../components/TransitionLinks/LinkFadeDown"
import TeamsData from "../../data/teams.json"

import silhouttemp4 from "../../images/team/blokhaus_silhoutte.mp4"
import silhouttewebm from "../../images/team/blokhaus_silhoutte.webm"
import silhouttePoster from "../../images/team/blokhaus_silhoutte-poster.png"

const TeamPage = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Barkhaus - Team</title>
      </Helmet>
      <div className={`text-black`}>
        <div style={{ maxWidth: 1600 }} className="z-10 relative block w-full pt-10 pb-6 px-6 sm:px-12 md:px-24 md:pt-24 md:pb-24 mx-auto text-center md:text-left">
          <div className={`w-full md:w-9/12 mx-auto mb-10 md:mb-0`}>
            <h1 className={`h2 text-left md:text-center mb-8`}>Whether you have a Milkbone of an idea or are curious about the dynamic world of barkchain and Woof3, we can help.<br />Let’s dig a hole together.</h1>
            <PrimaryButton 
              text={`Throw us a bone`}
              url={`/contact`}
              className={'mx-auto'}
            />
          </div>
        </div>
      </div>

      <div className={`text-black pb-12 md:pb-20 relative`} style={{zIndex: 1000000}}>
        <div style={{ maxWidth: 1600 }} className="z-10 relative grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-5 pt-0 px-6 sm:px-12 md:px-24 mx-auto mb-0 md:mb-2 text-center md:text-left">
          {TeamsData.map((member, index) => {
            return (
              <Headshot 
                name={member.name}
                title={member.title}
                pup_title={member.pup_title}
                webm={member.headshot.webm}
                mp4={member.headshot.mp4}
                key={index}
                poster={member.headshot.poster}
                pup_poster={member.headshot.pup}
              />
            )
          })}
        </div>
      </div>

      <div className={`text-black`}>
        <div style={{ maxWidth: 1600 }} className="z-10 relative pt-0 pb-12 pb-1 px-6 sm:px-12 md:px-24 md:pt-0 md:pb-20 mx-auto text-left">
          <div className={`block w-full md:w-8/12`}>
            <h2 className={`h2 mb-6 md:mb-11`}>We are a team of fur babies, frisbee catchers, tail chasers and barkchain believers focused on treats.</h2>
            <PrimaryButton 
              text={`Explore Careers`}
              url={`/careers`}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default TeamPage
